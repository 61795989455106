<template>
	<div class="detail-wrap">
		<top-search @changeMode="changeMode" :isHelp="true"></top-search>
		<div class="help_list">
			<div class="lists" v-for="(item,index) in helpList" :key="index">
				<div class="item" v-for="(cItem,cIndex) in item.child_list" :key="cIndex" :class="detail.id == cItem.id ? 'active' :''" @click="changeHelpDetail(cItem.id)">{{cItem.title}}</div>
			</div>
		</div>
		<div class="help_detail" v-loading="loading">
			<div class="tit">{{ detail.title }}</div>
			<div v-html="detail.content"></div>
			<div class="detail_time">{{ $util.timeStampTurnTime(detail.create_time) }}</div>
		</div>
		<!-- <el-breadcrumb separator="/" class="path">
			<el-breadcrumb-item :to="{ path: '/' }" class="path-home">
				<i class="n el-icon-s-home"></i>
				首页
			</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/cms/help' }">帮助列表</el-breadcrumb-item>
			<el-breadcrumb-item class="path-help">帮助详情</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="help-detail" v-loading="loading">
			<div class="title">{{ detail.title }}</div>
			<div class="info">
				<div class="time">{{ $util.timeStampTurnTime(detail.create_time) }}</div>
			</div>
			<div class="content" v-html="detail.content"></div>
		</div> -->
	</div>
</template>

<script>
import topSearch from '@/components/top_search';
import { helpDetail, helpList } from '@/api/cms/help';
export default {
	name: 'help_detail',
	components: {
		topSearch,
	},
	data: () => {
		return {
			detail: [],
			loading: true,
			current_mode:1,
			helpList: [],
		};
	},
	created() {
		this.id = this.$route.path.replace('/cms/help-', '');
		this.getHelpList()
		this.getDetail();
	},
	watch: {
		$route(curr) {
			this.id = curr.params.pathMatch;
			this.getDetail();
		}
	},
	methods: {
		changeHelpDetail(id){
			this.id = id ;
			this.getDetail();
		},
		getHelpList() {
		    helpList()
		        .then(res => {
		            if (res.code == 0 && res.data) {
		                var arr = [];
		                arr = res.data.slice(0, 4)
		                for (let i=0; i<arr.length; i++) {
		                    arr[i].child_list = arr[i].child_list.slice(0, 4);
		                }
		                this.helpList = arr
		            }
		        })
		        .catch(err => {})
		},
		changeMode(mode){
			this.current_mode = mode;
		},
		getDetail() {
			helpDetail({
				id: this.id
			})
			.then(res => {
				if (res.code == 0) {
					if (res.data) {
						this.loading = false;
						this.detail = res.data;
					} else {
						// this.$router.push({ path: '/cms/help' });
						this.$router.push('/error')
					}
				}
			})
			.catch(err => {
				this.loading = false;
				this.$message.error(err.message);
			});
		}
	}
};
</script>
<style lang="scss" scoped>
.detail-wrap {
	width: 100%;
	position: relative;
	padding-top: 30px;
}
.help_list{
	margin-top: 30px;
	background-color: #fff;
	display: flex;
	align-items: center;
	padding: 30px 76px 10px;
	border-radius: 10px;
	margin-bottom: 30px;
	justify-content: space-between;
	.lists{
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-right: 152px;
		&:last-child{
			margin-right: 0;
		}
		.item{
			color: #30373D;
			font-size: 15px;
			line-height: 21px;
			min-width: 117px;
			height: 42px;
			line-height: 42px;
			text-align: center;
			border-radius: 5px;
			cursor: pointer;
			background-color: #fff;
			margin-bottom: 20px;
			&.active{
				background-color: #F6F6FA;
			}
		}
	}
}
.help_detail{
	padding: 30px;
	border-radius: 10px;
	background-color: #fff;
	.tit{
		color: #30373D;
		font-size: 18px;
		line-height: 14px;
		text-align: center;
		font-weight: 600;
		margin-bottom: 20px;
	}
	.detail_time{
		color: #999;
		margin-top: 20px;
		text-align: center;
		font-size: 13px;
		line-height: 22px;
	}
}
.help-detail {
	background-color: #ffffff;
	padding: 10px;
	border-radius: 5px;
	margin: 10px 0;
	
	.title {
		text-align: center;
		font-size: 18px;
		margin: 10px 0;
	}
	.info {
		margin: 0 43px;
		border-bottom: 1px dotted #e9e9e9;
		.time {
			text-align: center;
			color: #838383;
			margin-bottom: 17px;
		}
	}
	.content {
		padding-top: 10px;
		margin: 0 65px;
	}
}
.path {
	padding: 15px 0;
}
</style>
